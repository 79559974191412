import React from "react";

import "./style.css";
import Button from "../Button";
import Reveal from "../Reveal";

function Suporte() {
  return (
    <div className="suporte_wrapper">
      <div className="suporte_container">
        <div className="suporte_left">
          <Reveal
            delay="0.2"
            component={
              <p>
                Nosso compromisso vai além da tecnologia. Na Meile, oferecemos
                suporte premium, garantindo que você tenha assistência
                especializada sempre que precisar. Estamos aqui para impulsionar
                o sucesso do seu negócio, fornecendo soluções confiáveis e
                personalizadas.
              </p>
            }
          />
        </div>

        <div className="suporte_right">
          <Reveal
            delay="0.4"
            component={
              <>
                <div className="detail_title">
                  <span />
                  Suporte Premium
                </div>
                <div className="suporte_headline">
                  Mais que serviços, uma parceria para o sucesso do seu{" "}
                  <strong>negócio.</strong>
                </div>

                <a
                  href="https://api.whatsapp.com/send?phone=553132117760&text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20solução!%20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button title={"contato"}></Button>
                </a>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Suporte;
