import React from "react";

import "./style.css";
import wpp from "../../assets/img/whatsapp.svg";
function Whatsapp() {
  return (
    <a
      href="https://api.whatsapp.com/send?phone=553132117760&text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20solução!%20"
      target="_blank"
      rel="noreferrer"
    >
      <div className="whataspp_container">
        <img src={wpp} alt="social" />
      </div>
    </a>
  );
}

export default Whatsapp;
