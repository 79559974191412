import React from "react";

import "./style.css";
import Button from "../Button";
import Reveal from "../Reveal";
function Plans() {
  return (
    <div className="plans_wrapper">
      <div className="plans_container">
        <div className="headline">planos</div>
        <div className="sheadline">Otimize seu trabalho.</div>
        <Reveal
          delay="0.2"
          component={
            <div className="plan_boxes_container">
              <div className="plan_box">
                <h1>Padrão</h1>

                <div className="most_popular">maior procura</div>
                <div className="price">
                  R$29,90<span>/mês</span>
                </div>

                {/* <Button title="experimente já" /> */}

                <p>Colabore e otimize os processos da sua equipe</p>

                <div className="list">
                  <h2>Inclui:</h2>
                  <div className="plan_item">A partir de 5 caixas</div>
                  <div className="plan_item">10GB por caixa</div>
                  <div className="plan_item">90 dias de arquivamento</div>
                  <div className="plan_item">Antispam premium</div>
                  <div className="plan_item">1 hospedagem de site</div>
                </div>
              </div>

              <div className="plan_box">
                <h1>Profissional</h1>

                <div className="price">
                  R$37,90<span>/mês</span>
                </div>

                {/* <Button title="experimente já" /> */}

                <p>Colabore e otimize os processos da sua equipe</p>

                <div className="list">
                  <h2>Inclui:</h2>
                  <div className="plan_item">A partir de 5 caixas</div>
                  <div className="plan_item">25GB por caixa</div>
                  <div className="plan_item">1 ano de arquivamento</div>
                  <div className="plan_item">Antispam premium</div>
                  <div className="plan_item">1 hospedagem de site</div>
                </div>
              </div>

              <div className="plan_box">
                <h1>Corporativo</h1>

                <div className="price">
                  R$55,90<span>/mês</span>
                </div>

                {/* <Button title="experimente já" /> */}

                <p>Colabore e otimize os processos da sua equipe</p>

                <div className="list">
                  <h2>Inclui:</h2>
                  <div className="plan_item">A partir de 5 caixas</div>
                  <div className="plan_item">50GB por caixa</div>
                  <div className="plan_item">2 anos de arquivamento</div>
                  <div className="plan_item">Antispam premium</div>
                  <div className="plan_item">1 hospedagem de site</div>
                </div>
              </div>

              <div className="plan_box">
                <h1>Saiba mais</h1>
                <a
                  href="https://api.whatsapp.com/send?phone=553132117760&text=Olá,%20gostaria%20de%20saber%20mais%20sobre%20a%20solução!%20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button title="entre em contato conosco" />
                </a>

                <p>
                  Descubra ferramentas poderosas que vão potencializar o sucesso
                  do seu negocio
                </p>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default Plans;
