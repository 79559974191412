import React from "react";

import "./style.css";
import cover from "../../assets/himg/mam.png";
function Video() {
  return (
    <div className="video_wrapper">
      <div className="video_container" id="video">
        <div className="vid">
          <video controls poster={cover}>
            <source
              src={
                "https://firebasestorage.googleapis.com/v0/b/spotify-cf1fe.appspot.com/o/Meile_Manifesto.mp4?alt=media&token=1dd88573-4493-479a-a174-655f718a3f7f"
              }
            />
          </video>
        </div>
      </div>
    </div>
  );
}

export default Video;
