import React, { useEffect } from "react";
import $ from "jquery";
import "./style.css";
import logo from "../../assets/img/MEILE_2024_MARCA-03.svg";
import Reveal from "../Reveal";
import AnchorLink from "react-anchor-link-smooth-scroll";
function Menu() {
  useEffect(() => {
    $(document).ready(function ($) {
      $(window).scroll(function () {
        if ($(window).scrollTop() >= 20) {
          $(".menu_wrapper").addClass("active");
        } else {
          $(".menu_wrapper").removeClass("active");
        }
      });
    });
  }, []);
  return (
    <div className="menu_wrapper">
      <div className="menu_container">
        <div className="logo">
          <Reveal delay="0.2" component={<img src={logo} alt="social" />} />
          <div className="nav">
            <Reveal
              delay="0.4"
              classe={"navop"}
              component={
                <AnchorLink href="#home">
                  <div>Home</div>
                </AnchorLink>
              }
            />
            <Reveal
              delay="0.6"
              classe={"navop"}
              component={
                <AnchorLink href="#produtos">
                  <div>Produtos </div>
                </AnchorLink>
              }
            />
            <Reveal
              delay="0.8"
              classe={"navop"}
              component={
                <a
                  href="https://partner.meile.com.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div>Partner</div>
                </a>
              }
            />

            <Reveal
              delay="1"
              classe={"navop"}
              component={
                <AnchorLink href="#video">
                  <div>Sobre nós </div>
                </AnchorLink>
              }
            />
          </div>
        </div>
        <Reveal
          delay="1.2"
          component={
            <a
              href="https://partner.meile.com.br/opportunities/nova-oportunidade"
              target="_blank"
              rel="noreferrer"
            >
              <button>inicie agora!</button>
            </a>
          }
        />
      </div>
    </div>
  );
}

export default Menu;
