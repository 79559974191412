import "./App.css";
import Banner from "./components/banner";
import Info from "./components/info";
import Step from "./components/step";
import Footer from "./components/footer";
import Whatsapp from "./components/whatsapp";
import Menu from "./components/menu";
import Subscribe from "./components/Subscribe";
import { OpenContextProvider } from "./Context/store";
import Clients from "./components/clients";
import Plans from "./components/plans";
import Suporte from "./components/suporte";
import Video from "./components/video";
import Team from "./components/team";
function App() {
  return (
    <div className="App">
      <OpenContextProvider>
        <Menu />
        <Banner />
        <Clients />
        <Info />
        <Plans />
        <Step />
        <Suporte />
        <Video />
        <Team />
        <Footer />
        <Whatsapp />
        <Subscribe />
      </OpenContextProvider>
    </div>
  );
}

export default App;
