import React from "react";
import Reveal from "../Reveal";
import "./style.css";
import team from "../../assets/himg/team.png";
function Team() {
  return (
    <div className="team_wrapper">
      <div className="team_container">
        <div className="team_left">
          <Reveal
            delay="0.2"
            component={
              <>
                <div className="detail_title">
                  <span />
                  Meile Team
                </div>
                <div className="team_headline">MeileTeam</div>
                <p>
                  Aqui, celebramos conquistas, compartilhamos insights e
                  fortalecemos os laços que impulsionam o sucesso mútuo. Em um
                  ambiente que valoriza a inovação e o comprometimento, cada
                  integrante do nosso time desafia os limites, buscando
                  continuamente aprimorar nossos serviços e superar as
                  expectativas dos nossos clientes. A diversidade de talentos e
                  perspectivas enriquece nosso avanço, permitindo-nos enfrentar
                  desafios com criatividade e determinação.
                </p>
              </>
            }
          />
        </div>

        <div className="team_right">
          <Reveal delay="0.4" component={<img src={team} alt="team"></img>} />
        </div>
      </div>
    </div>
  );
}

export default Team;
