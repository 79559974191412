import React from "react";

import "./style.css";
import im from "../../assets/himg/Frame.svg";
import im1 from "../../assets/himg/image 2.svg";
import im2 from "../../assets/himg/image 3.svg";
import im3 from "../../assets/himg/image 4.svg";
import im4 from "../../assets/himg/image 5.svg";
import im5 from "../../assets/himg/image 6.svg";
import im6 from "../../assets/himg/image 7.svg";

function Clients() {
  return (
    <div className="clients_wrapper">
      <div className="Marquee">
        <div className="Marquee-content">
          <div className="Marquee-tag">
            <img src={im} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im1} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im2} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im3} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im4} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im5} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im6} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im1} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im2} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im3} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im4} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im5} alt="banner" />
          </div>
          <div className="Marquee-tag">
            <img src={im6} alt="banner" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
