import React from "react";

import "./style.css";
import Reveal from "../Reveal";
function Banner() {
  return (
    <div className="banner_wrapper" id="home">
      <div className="banner_container">
        <div className="banner_info_left">
          <Reveal
            delay="0.4"
            component={
              <div className="banner_headline">
                MAV
                <br /> AGORA É<br /> MEILE
              </div>
            }
          />

          <Reveal
            delay="0.6"
            component={
              <div className="banner_subheadline">
                <strong>Bem-vindo a Meile, </strong>
                sua parceira na jornada para uma infraestrutura de hospedagem
                mais eficiente e segura. Potencialize suas operações com nosso
                Meile-mail profissional, onde a excelência encontra a
                praticidade.
              </div>
            }
          />
        </div>
        <div className="banner_info_right"></div>
      </div>
    </div>
  );
}

export default Banner;
