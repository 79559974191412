import React, { useState } from "react";

import "./style.css";
import bimg from "../../assets/himg/infimg.svg";
import Button from "../Button";
import { useOpenContext } from "../../Context/store";
import Reveal from "../Reveal";
function Info() {
  const [isShowing, setIsShowing] = useState(false);
  const { open, setOpen } = useOpenContext();

  const toggle = () => {
    setIsShowing(!isShowing);
    setOpen(!open);
    localStorage.setItem("popup", "true");
  };
  return (
    <div className="info_wrapper">
      <div className="info_container">
        <div className="info_info_left">
          <Reveal
            delay="0.2"
            component={
              <div className="detail_title">
                <span />
                Email Empresarial
              </div>
            }
          />
          <Reveal
            delay="0.4"
            component={
              <div className="info_headline">
                Comunique-se sem limites, e-mail empresarial Meile
              </div>
            }
          />
          <div className="shead">O coração de sua comunicação corporativa.</div>
          <Reveal
            delay="0.6"
            component={
              <div className="info_subheadline">
                Nossos e-mails corporativos transcendem as expectativas,
                oferecendo um antispam premium, desenvolvido por nós, com uma
                eficácia de 99,9%. Viva sem preocupações ao arquivar todas as
                suas mensagens por até 10 anos, com a opção de cópias
                autenticadas para máxima segurança. Nosso suporte humanizado,
                acessível por 5 canais de comunicação, garante que você tenha
                sempre o auxílio necessário. E para impulsionar ainda mais o seu
                negócio, nossos planos de e-mail incluem hospedagem de site
                grátis. Descubra como podemos elevar sua empresa a novos
                patamares, proporcionando uma experiência completa e integrada.
              </div>
            }
          />
          <Reveal
            delay="0.8"
            component={
              <div onClick={toggle}>
                <Button title="agende sua apresentação com um especialista" />
              </div>
            }
          />
        </div>
        <div className="info_info_right">
          <Reveal delay="1" component={<img src={bimg} alt="info" />} />
        </div>
      </div>
    </div>
  );
}

export default Info;
