import React from "react";

import "./style.css";
import Reveal from "../Reveal";
function Step() {
  return (
    <div className="step_wrapper">
      <Reveal
        delay="0.2"
        component={
          <div className="step_container" id="produtos">
            <div className="left_step">
              <div className="box_products">
                <div className="dot"></div>nossos
                <br />
                produtos
              </div>
              <div className="box_products">
                <h1>Email Coporativo</h1>
                <p>Hospedagem completa de e-mail profissional.</p>
              </div>
              <div className="box_products">
                <h1>Hospedagem</h1>
                <p>Estabilidade, segurança e recursos exclusivos.</p>
              </div>
            </div>
            <div className="right_step">
              <div className="box_products">
                <h1>Box</h1>
                <p>
                  Melhore o desempenho do seu negócio com a nossa plataforma
                  colaborativa, ideal para o compartilhamento de arquivos,
                  contatos, calendários, tarefas e muito mais!
                </p>
              </div>
              <div className="box_products">
                <h1>Antispam</h1>
                <p>
                  Garanta 99,9% de eficácia na proteção contra spams, fraudes e
                  phishing (roubo de informações), realizamos as filtragens dos
                  seus e-mails e entregamos as mensagens ao seu servidor local.
                </p>
              </div>
              <div className="box_products">
                <h1>VPS</h1>
                <p>
                  Aumente o desempenho do seu site com nossa hospedagem SSD.
                </p>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Step;
